.App {
  min-height: 100vh;
  display: flex;
  background-size: cover;
  padding: 2rem;
}

@media (max-width: 700px) {
  .App {
    padding: unset;
  }
}

.relativeContainer {
  width: 280px;
  position: relative;
  transition: width 0.8s;
}

.relativeContainerCut {
  width: 80px;
  position: relative;
  transition: width 0.9s;
}

.navbar {
  top: -50px;
  position: absolute;
  overflow: hidden;
  padding: 10px 10px;
  width: 200px;
  height: 500px;
  transition: width 0.6s, height 0.6s;
}

.lmao {
  top: -50px;
  position: absolute;
  overflow: hidden;
  padding: 10px 10px;
  width: 55px;
  height: 100px;
  transition: width 0.6s, height 0.6s;
}

.navbarWrapper {
  position: relative;
  height: 400px;
  padding: 10px;
  border-radius: 30px;
  background-color: white;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.7);
  transition: all 0.8s;
}

.navbarWrapperDesactive {
  position: relative;
  height: 400px;
  padding: 10px;
  border-radius: 30px;
  background-color: transparent;
  transition: all 0.8s;
}

.drop {
  background-color: white;
  z-index: 2;
  position: absolute;
  margin-top: 50px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.58);
  -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.58);
  -moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.58);
}

.drop:hover {
  cursor: pointer;
}

.arrow {
  height: 70%;
  width: 70%;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #005973;
}

.navbarWrapperDesactive ul {
  padding: 8px;
  margin: 0;
  margin-top: 90px;
  list-style: none;
}

.navbarWrapperDesactive li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: cadetblue;
  font-weight: bold;
  padding: 8px 2px;
  border-bottom: 1px solid lightgrey;
}

a {
  /* color: #28b0ee; */
  text-decoration: none;
}

.navbarWrapperDesactive li:hover {
  cursor: pointer;
}

.navbarWrapper ul {
  padding: 8px;
  margin: 0;
  margin-top: 90px;
  list-style: none;
}

.navbarWrapper li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: cadetblue;
  font-weight: bold;
  padding: 8px 2px;
  border-bottom: 1px solid lightgrey;
  transition: padding 0.2s;
}

.navbarWrapper li:hover {
  cursor: pointer;
  padding-left: 10px;
  transition: padding 0.2s;
}

.content {
  flex: 1;
}

.content1 {
  overflow: hidden;
  margin: 50px 10px 10px 10px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 82vh;
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
