.custom-modal {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 600px;
  text-align: center;
  background-color: #fcfcfd;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 0px;
  border-radius: 30px;
  overflow: hidden;
  outline: none;
}
.custom-modal_2 {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 60vw;
  text-align: center;
  background-color: #fcfcfd;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 0px;
  border-radius: 30px;
  overflow: hidden;
  outline: none;
  height: 95vh;
}

.custom-modal__container {
  padding: 37px 27px;
  max-height: 490px;
  overflow: auto;
  overflow-x: hidden;
}

.custom-modal__container-2 {
  padding: 37px 27px;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.custom-modal__children {
  text-align: justify;
  margin-top: 58px;
}

.custom-modal__box-title {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  padding: 10px 27px;
  min-height: 70px;
  display: flex;
  align-items: center;

  border-bottom: 1px #ddd solid;
}

.custom-modal__title{
  color: #333;
}
