.modules__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 12px;
  margin: 11px 12px;
}
.modules__header2 {
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}
.modules__header-title {
  font-size: 22px !important;
  font-weight: bold !important;
  font-weight: bolder;
  padding-bottom: 2rem;
}

.page-title {
  font-weight: bold !important;
  font-size: 1.3em !important;
  color: #000000;
}

.header{
  @media (max-width:1100px){
    display: flex;
    flex-direction: column;
  }
}

.buttonExit {
  position: absolute !important;
  right: 10px;
  gap: 8px;
  color:#0776d3;
  @media (max-width:1100px){
    position: static !important;
    align-self: flex-end;
  }
}

.buttonDrawer {
  width: 40px;
  background: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 15px 0 0;
  button {
    margin: 0;
  }
}

.button-header {
  font-weight: bold !important;
  letter-spacing: 0.09px !important;
}

.registerImage {
  max-width: 250px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(169 178 191 / 32%) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.7em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(169 178 191 / 32%);
  border-radius: 10px;
}
