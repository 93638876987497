.app {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
}

// .background {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #fff;
//     background-image: url('../../img/fondo_PremiosCyT_LoginV01.webp');
//     top: 50;
//     background-size: cover; // Asegura que la imagen cubra todo el contenedor
//     background-position: center; // Centra la imagen en el contenedor si no ocupa todo el espacio
//      padding: 1rem 0 0 0;
//     // margin: 1rem
// }
.background {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 17px;
    background: url('../../img/imagesola_PremiosCyT_LoginV01_02.webp') right center no-repeat;
    // background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 100%), 
    //  url('../../img/imagesola_PremiosCyT_LoginV01_02.webp') left center no-repeat;
    background-size: contain;

    @media (max-width:775px) {
        background-size: cover;
    }
}


.registerClass {
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    min-width: 300px;
    max-width: 50rem;
    border-radius: 50px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
}

.centerWrapper {
    // background-color: rgba(255, 255, 255, 1);
    color: #005b9a;
    text-transform: initial;
    min-width: 300px;
    max-width: 300px;
    margin: auto;
    padding: 20px 30px;
    border-radius: 15px;
    // box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
    // -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
    // -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
    // background-color:#e9e9e9d5;

    h1 {
        font-size: 2.4em;
        margin-top: 10px;
        margin-bottom: 5px;
        text-align: center;
        text-transform: none !important;
    }
}

.loginImage {
    margin: 0;
    width: 40% !important;
}

.logo {
    height: 80px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

}


.group {
    position: relative;
    margin-top: 30px;
    margin-bottom: 10px;
}

.userOption {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userOption p {
    color: gray;
}






.field {
    display: flex;
    flex-direction: column;
}

.field label {
    margin-top: 10px;
    margin-bottom: 10px;
}

.buttonWrapper {
    padding: 10px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #005973;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
}

.button:after {
    content: " ";
    width: 0%;
    height: 100%;
    background: #FFD401;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
}

.button:hover {
    cursor: pointer;
}

.button:hover::after {
    right: auto;
    left: 0;
    width: 100%;
}

.button span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 10px 15px;
    color: #fff;
    font-size: 1.125em;
    font-weight: 700;
    letter-spacing: 0.3em;
    z-index: 20;
    transition: all 0.3s ease-in-out;
}

.button:hover span {
    color: #005973;
    animation: scaleUp 0.3s ease-in-out;
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}


/*loading*/
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
}

.spinner .dot {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
}

.spinner .dot::after {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: white;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.spinner .dot {
    animation: spin 2s infinite;
}

.spinner .dot:nth-child(2) {
    animation-delay: 100ms;
}

.spinner .dot:nth-child(3) {
    animation-delay: 200ms;
}

.spinner .dot:nth-child(4) {
    animation-delay: 300ms;
}

.spinner .dot:nth-child(5) {
    animation-delay: 400ms;
}

/*end loading*/

.olvido {
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #653188;
}

.olvido a {
    color: #005b9a;
    font-size: 15px;
    font-weight: bold;
    text-transform: initial;
}


.button-login {
    color: #fff !important;
    border-color: #003366 !important;
    background-color: #003366 !important;
    border-radius: 13px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
    width: 100% !important;
    padding: 5px 25px !important;
    text-transform: initial !important;
}

.input-login {

    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    text-transform: initial;
}

.registerImage {
    max-width: 250px;
}

.loginImage {
    width: 80%;
    align-self: flex-start; // Esto alineará la imagen al comienzo del contenedor
    @media (max-width:900px) {
        max-width: 70% !important;
        width: 100% !important;
    }
}

.containerForm {

    @media (max-width:900px) {
        background-color: rgb(243 243 243 / 65%);;
        border-radius: 25px;
    }

}

