@font-face {
  font-family: ralewayBold;
  src: url(Raleway-Bold.ttf);
}

@font-face {
  font-family: ralewayRegular;
  src: url(Raleway-Regular.ttf);
}

body, html {
  margin: 0;
  min-height: 100vh;
  font-family: ralewayRegular;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(169 178 191 / 32%) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.7em;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(169 178 191 / 32%);
  border-radius: 10px;
}

.archivos .filepond--credits {
  display: none;
}

.errors-archivos .filepond--drop-label {
  border: 1px red solid;
  color: red;
  border-radius: 15px;
}

/* .css-o4b71y-MuiAccordionSummary-content{
  justify-content: flex-end !important;
} */

.css-1uav7vo-MuiButtonBase-root-MuiTab-root {
  align-items: flex-end !important;
}

.borde {
  position: fixed;
  bottom: -30px;
  right: 0;
  height: 32%;
}

.borde img {
  height: 100%;
}

.titles {
  font-family: ralewayBold;
}

.paragraph {
  font-family: ralewayRegular;
}

/* Media queries para teléfonos */
@media screen and (max-width: 768px) {
  .borde {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

