.titulo {
  font-size: 26px;
  font-weight: normal;
  width: 100%;
  text-align: end;
  font-family: ralewayBold;
}

.imageDisabled {
  @media (max-width: 460px) {
    display: none;
  }
}

.premio-container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 25px;

  @media (max-width: 975px) {
    grid-template-columns: 1fr;
  }
}
