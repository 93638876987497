.parrafo {
  font-size: 13px;
  color: #90908f;
  margin: 0 0 15px 0;
}

.disabled {
  display: none;
}

.switch__Position {
  position: 'absolute';
  right: '1px';
  top: '-2px';
  flex-direction: 'row';
  align-items: 'center';
}

.switch__Position__M11 {
  position: 'absolute';
  right: '1px';
  top: '0px';
  flex-direction: 'row';
  align-items: 'center';
}

.title {
  font-family: ralewayBold;
  font-weight: normal;
}
